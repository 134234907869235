import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 17px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  margin-bottom: 28px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 21px;
  }
`;

export const Price = styled.span`
  display: block;
  font-size: 26px;
  line-height: 26px;
`;

export const UnitPrice = styled.span`
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.grey};
`;

export const Materials = styled.span`
  display: block;
  font-size: 18px;
  color: ${(props) => props.theme.colors.grey};
  margin-bottom: 41px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 41px;
`;

export const Form = styled.form`
  margin-bottom: 40px;
`;

export const LabelCss = css`
  width: 90px;
`;

export const DropdownCss = css`
  width: 160px;
`;

export const CustomColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  > * {
    transform: scale(0.8);
  }
`;
