import React from "react";
import PropTypes from "prop-types";

import { StyledLink, Title, Price } from "./ProductItem.styles";

import { getProductItems } from "utils/getProductItems";

import withLocation from "components/withLocation";
import { getCheapestProductItem } from "utils/getCheapestProductItem";

const ProductItem = ({ image, title, price, slug, location, items }) => {
  const productItems = getProductItems(items);

  const cheapestProductItem = getCheapestProductItem(productItems);

  const productPrice =
    cheapestProductItem !== undefined
      ? cheapestProductItem.price / cheapestProductItem.quantity
      : price;

  return (
    <StyledLink to={`${location.pathname}/${slug}`}>
      {image}
      <Title>{title}</Title>
      <Price>{productPrice.toFixed(2)}&euro;</Price>
    </StyledLink>
  );
};

ProductItem.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default withLocation(ProductItem);
