const productItemRegex = /^(\d+) - (\d+|\d+\.\d+)$/;

export function getProductItems(items) {
  if (items === null) {
    return [];
  }

  return items.map((item) => {
    const match = productItemRegex.exec(item.title);
    return {
      quantity: parseInt(match[1], 10),
      price: parseFloat(match[2]),
    };
  });
}
