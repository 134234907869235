export function getCheapestProductItem(items) {
  if (items === null || items.length === 0) {
    return undefined;
  }

  let cheapestItem = items[0];

  for (let i = 1; i < items.length; i++) {
    const itemPrice = items[i].price / items[i].quantity;
    const cheapestItemPrice = cheapestItem.price / cheapestItem.quantity;

    if (itemPrice < cheapestItemPrice) {
      cheapestItem = items[i];
    }
  }

  return cheapestItem;
}
